import React from "react"

import Layout from "../v0/layouts/Layout"
import SEO from "../v0/seo/seo"

const SlackBot = () => (
  <Layout>
    <SEO title="Slackbot success" />
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h2 style={{ margin: "auto auto 20px auto" }}>Great!</h2>
      <h3 style={{ margin: " auto" }}>
        The DevWell slackbot has been installed!
      </h3>
      <h3 style={{ margin: " auto" }}>
        Check out your Slack to see it in action.
      </h3>
    </div>
  </Layout>
)

export default SlackBot
